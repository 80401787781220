








































import { defineComponent, ref, computed, ComponentInstance } from '@vue/composition-api';

import useRoute from '@/packages/hooks/useRoute';

import { useLayoutLoadingStatus } from '@/composables/layout';

import AppRedesignNoticeCard from '@/components/_newDesign/App/AppRedesignNoticeCard.vue';

import AppHeader from '@/components/_newDesign/App/AppHeader.vue';
import AppSideBar from '@/components/_newDesign/App/AppSideBar/AppSideBar.vue';
import AppFooter from '@/components/_newDesign/App/AppFooter.vue';
import AppBottomNavigation from '@/components/_newDesign/App/AppBottomNavigation.vue';

import AppDefaultLoader from '@/components/_newDesign/App/AppDefaultLoader.vue';

import SearchSideBar from '@/modules/search/components/SearchSideBar.vue';

import ModallyRoot from '@/services/modally/ModallyRoot.vue';
import NotifyRoot from '@/services/notify/NotifyRoot.vue';

export default defineComponent({
  components: {
    AppRedesignNoticeCard,
    AppHeader,
    AppSideBar,
    AppFooter,
    AppBottomNavigation,
    SearchSideBar,
    ModallyRoot,
    NotifyRoot,
  },
  setup(props, context) {
    const route = useRoute(context);

    const { isLoading } = useLayoutLoadingStatus();

    const headerRef = ref<ComponentInstance | null>(null);

    const loaderComponent = computed(() => route.value.meta.loader ?? AppDefaultLoader);

    const headerVisible = computed(() => route.value.meta?.header?.visible ?? true);
    const footerVisible = computed(() => route.value.meta?.footer?.visible ?? true);

    const onMutateHeader = () => {
      if (!headerRef.value || !(headerRef.value.$el instanceof HTMLElement)) return;

      document.documentElement.style.setProperty('--app-header-height', `${headerRef.value.$el.offsetHeight}px`);
    };

    return {
      isLoading,
      headerRef,
      onMutateHeader,
      loaderComponent,
      headerVisible,
      footerVisible,
    };
  },
  computed: {
    isEditPage() {
      return this.$route.path.includes('/edit');
    },
  },
});
