/* eslint-disable max-classes-per-file */

import { Getters, Mutations, Actions, Module, createMapper, createComposable } from 'vuex-smart-module';

import { ClientsApi, UserSettingsApi } from '@/api';

import { FetchClientsV2Payload } from '@/api/modules/clients.api';

import { Client } from '@/models/Client';

const initFilters = () => ({
  page: 1,
  limit: 50,
  state: 'all' as FetchClientsV2Payload['state'],
});

class ModuleState {
  clients: Client[] = [];

  totalCount = 0;

  filters: FetchClientsV2Payload = initFilters();

  filtersChanged = false;

  isLoading = false;
}

class ModuleGetters extends Getters<ModuleState> {
  get clients() {
    return this.state.clients;
  }

  get totalCount() {
    return this.state.totalCount;
  }

  get filters() {
    return this.state.filters;
  }

  get filtersChanged() {
    return this.state.filtersChanged;
  }

  get isLoading() {
    return this.state.isLoading;
  }
}

class ModuleMutations extends Mutations<ModuleState> {
  SET_CLIENTS(payload: Client[]) {
    this.state.clients = payload;
  }

  SET_TOTAL_COUNT(payload: number) {
    this.state.totalCount = payload;
  }

  ADD_CLIENTS(payload: Client[]) {
    this.state.clients = [...this.state.clients, ...payload];
  }

  SET_FILTERS(payload: FetchClientsV2Payload) {
    this.state.filters = payload;
  }

  SWITCH_CLIENT_IS_FAVOIRITE(payload: Client) {
    const clientIdx = this.state.clients.findIndex((client) => client.id === payload.id);

    if (clientIdx === -1) return;

    const updatedClient = { ...payload, isFavourite: !payload.isFavourite };

    this.state.clients.splice(clientIdx, 1, updatedClient);
  }

  SET_FILTERS_CHANGED(payload: boolean) {
    this.state.filtersChanged = payload;
  }

  SET_IS_LOADING(value: boolean) {
    this.state.isLoading = value;
  }
}

class ModuleActions extends Actions<ModuleState, ModuleGetters, ModuleMutations, ModuleActions> {
  async FETCH_CLIENTS() {
    this.commit('SET_IS_LOADING', true);

    try {
      const res = await ClientsApi.fetchClientsV2(this.getters.filters);

      if (this.getters.filters.page === 1) {
        this.commit('SET_CLIENTS', res.data.list);
      } else {
        this.commit('ADD_CLIENTS', res.data.list);
      }

      this.commit('SET_TOTAL_COUNT', res.data.totalRows);
    } finally {
      this.commit('SET_IS_LOADING', false);
    }
  }

  async ADD_FAVORITE_CLIENT(clientId: string) {
    await UserSettingsApi.addFavoriteClient(clientId);
  }

  async DELETE_FAVORITE_CLIENT(clientId: string) {
    await UserSettingsApi.deleteFavoriteClient(clientId);
  }

  async TOGGLE_CLIENT_IS_FAVORITE(client: Client) {
    if (client.isFavourite) {
      await this.dispatch('DELETE_FAVORITE_CLIENT', client.id);
    } else {
      await this.dispatch('ADD_FAVORITE_CLIENT', client.id);
    }

    this.commit('SWITCH_CLIENT_IS_FAVOIRITE', client);
  }

  UPDATE_FILTERS(filters: Partial<FetchClientsV2Payload>) {
    const page = filters.page || 1;

    const newFilters = {
      ...this.getters.filters,
      ...filters,
      page,
    };

    this.commit('SET_FILTERS', newFilters);
    this.commit('SET_FILTERS_CHANGED', true);
  }

  RESET_FILTERS() {
    this.commit('SET_FILTERS', initFilters());
    this.commit('SET_FILTERS_CHANGED', false);
  }

  NEXT_PAGE() {
    if (this.getters.clients.length >= this.getters.totalCount) return;

    this.dispatch('UPDATE_FILTERS', { page: this.getters.filters.page + 1 });
  }
}

const module = new Module({
  state: ModuleState,
  getters: ModuleGetters,
  mutations: ModuleMutations,
  actions: ModuleActions,
});

export const useClientsStore = createComposable(module);

export const clientsStoreMapper = createMapper(module);

export default module;
