
































import { defineComponent } from '@vue/composition-api';

import ListLoader from '@/components/_newDesign/common/loaders/ListLoader.vue';

export default defineComponent({
  components: {
    ListLoader,
  },
});
